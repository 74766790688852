@import 'src/style/media';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 72px;
  padding: 0 24px;
  background-color: #dc7059;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #ffffff;

  @media screen and(max-width: 1024px) {
    height: 50px;
  }

  @media screen and(max-width: 600px) {
    flex-direction: column-reverse;
    height: 100%;
  }

  @media screen and(min-width: 1920px) {
    font-size: 20px;
    line-height: 28px;
  }

  @media screen and(min-width: 2560px) {
    height: 90px;
    font-size: 24px;
    line-height: 30px;
  }

  @media screen and(min-width: 3200px) {
    height: 100px;
    font-size: 28px;
    line-height: 36px;
  }

  @media screen and(min-width: 3840px) {
    height: 110px;
    font-size: 30px;
    line-height: 38px;
  }

  @media screen and(min-width: 4096px) {
    height: 120px;
    font-size: 32px;
    line-height: 40px;
  }
}

.transparent {
  background-color: transparent;
}

.iconsContainer {
  display: flex;

  @media screen and(max-width: 600px) {
    padding: 10px 0;
  }
}

.icon {
  margin: 0 4px;
  transition: transform 0.4s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  @media screen and(min-width: 1920px) {
    width: 40px;
    height: 40px;
  }

  @media screen and(min-width: 2560px) {
    width: 50px;
    height: 50px;
  }

  @media screen and(min-width: 3200px) {
    width: 60px;
    height: 60px;
  }

  @media screen and(min-width: 3840px) {
    width: 70px;
    height: 70px;
  }

  @media screen and(min-width: 4096px) {
    width: 80px;
    height: 80px;
  }
}

.year {
  @media screen and(max-width: 600px) {
    margin-bottom: 10px;
  }
}

.navigation {
  display: flex;
  flex-direction: 'row';
  font-size: 16px;
  line-height: 22px;
  flex-wrap: wrap;
  justify-content: space-between;

  a {
    text-decoration: none;
    color: #ffffff;
  }

  @media screen and(max-width: 600px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
}

.navItem {
  margin: 0 10px;
  transition: transform 0.4s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  a {
    text-decoration: none;
  }

  @media screen and(max-width: 600px) {
    margin: 0;
  }
}
