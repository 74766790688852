@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:wght@400;700&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  outline: 0;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-weight: inherit;
}

body {
  font-family: 'Mulish', sans-serif;
}

ul,
ol {
  list-style: none;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

img {
  user-select: none;
}

button,
input {
  outline: none;
  border: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

.app-loader {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
