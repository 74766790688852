.button {
  display: flex;
  align-items: center;
  width: 180px;
  height: 53px;

  & img {
    width: 100%;
  }

  @media screen and(max-width: 600px) {
    width: 164px;
    height: 48px;
  }

  @media screen and(max-width: 360px) {
    width: 136px;
    height: auto;
  }

  @media screen and(min-width: 1920px) {
    width: 220px;
    height: auto;
  }

  @media screen and(min-width: 2560px) {
    width: 250px;
  }

  @media screen and(min-width: 3200px) {
    width: 280px;
  }

  @media screen and(min-width: 3840px) {
    width: 360px;
    height: auto;
  }
}

.google {
  margin-right: 16px;
  cursor: pointer;

  @media screen and(max-width: 600px) {
    margin-right: 8px;
  }

  @media screen and(max-width: 280px) {
    margin-right: unset;
    padding: 8px 0;
  }
}

.apple {
  margin-left: 16px;

  @media screen and(max-width: 600px) {
    margin-left: 8px;
  }

  @media screen and(max-width: 280px) {
    margin-left: unset;
    padding: 8px 0;
  }
}
