@mixin configSemiBold {
  font-family: 'Mulish';
  font-weight: 600;
}

@mixin configRegular {
  font-family: 'Mulish';
  font-weight: normal;
  font-style: normal;
}

@mixin configBold {
  font-family: 'Mulish';
  font-weight: bold;
}

@mixin configBolder {
  font-family: 'Mulish';
  font-weight: 800;
}
