.block {
  background-color: #fff;
  box-shadow: 0px 16px 22px rgba(0, 0, 0, 0.1), 0px 34px 55px rgba(0, 0, 0, 0.05), 0px 13px 66px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  padding: 50px 44px;
  color: #000;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.4px;
  width: 430px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 25px;
}

.title {
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
}

.form {
  display: flex;
  flex-direction: column;
}

.label {
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.408px;
  color: #4f4f4f;
}

.input {
  border: none;
  border-bottom: 1px solid #bdbdbd;
  background-color: transparent;
  outline: none;
  padding-top: 10px;
  padding-bottom: 4px;
}

.error {
  color: #e83326;
  margin-top: 4px;
}

.button {
  background-color: #dc7059;
  border-radius: 36px;
  border: none;
  outline: none;
  padding: 14px 16px;
  color: #ffffff;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  margin-top: 12px;
}
