// fonts
$xxxLarge: 40px;
$xxLarge: 32px;
$xLarge: 24px;
$large: 20px;
$semiLarge: 18px;
$regular: 16px;
$medium: 14px;
$small: 12px;

// containers
$largeContainer: 1150px;
$container: 1045px;
// responsive
$tabletContainer: 850px;

// page wrapper
$wrapper: 1440px;
