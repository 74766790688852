$black: #000000;
$white: #ffffff;

$red: #e83326;

// background
$background: #f6f6f6;

// orange
$orange: #dc7059;
$whire-coral: #e7e7e8;

// grey
$light-grey: #9597a1;
$lighter-grey: #bdbdbd;
$grey: #828282;
$dark-grey: #4f4f4f;
