@import 'src/style/size';
@import 'src/style/colors';
@import 'src/style/typographyConfig';

@mixin fontXXLarge {
  @include configSemiBold;

  line-height: $xxxLarge;
  font-size: $xxLarge;
}

@mixin fontXLarge {
  line-height: $xxLarge;
  font-size: $xLarge;
}

@mixin fontLarge {
  @include configRegular;

  line-height: $xLarge;
  font-size: $semiLarge;
}

@mixin fontRegular {
  @include configRegular;

  line-height: $large;
  font-size: $regular;
}

@mixin fontMedium {
  @include configRegular;

  line-height: $large;
  font-size: $medium;
}

@mixin fontSmall {
  line-height: $regular;
  font-size: $small;
}
