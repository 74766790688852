.wrapper {
  display: flex;
  position: relative;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
}

.bgImageTop {
  display: flex;
  position: relative;
  width: 100%;
  height: 780px;
  background: center / cover no-repeat url('./../../assets/images/main_page_bg_top.png');
  clip-path: polygon(0 0, 100% 0, 100% 85%, 50% 100%, 50% 100%, 0 85%);

  @media screen and(max-width: 1280px) {
    height: 680px;
  }

  @media screen and(max-width: 1152px) {
    height: 700px;
  }

  @media screen and(max-width: 1024px) and(min-height: 1366px) {
    height: 800px;
    clip-path: polygon(0 0, 100% 0, 100% 88%, 50% 100%, 50% 100%, 0 88%);
  }

  @media screen and(max-width: 960px) {
    height: 500px;
  }

  @media screen and(max-width: 600px) {
    height: 650px;
    background: center / cover no-repeat url('./../../assets/images/main_page_bg_top_mobile.png');
    clip-path: polygon(0 0, 100% 0, 100% 93%, 50% 100%, 50% 100%, 0 93%);
  }

  @media screen and(max-width: 360px) {
    height: 500px;
  }

  @media screen and(min-width: 1920px) {
    height: 900px;
  }

  @media screen and(min-width: 2560px) {
    height: 1200px;
  }

  @media screen and(min-width: 3200px) {
    height: 1300px;
  }

  @media screen and(min-width: 3840px) {
    height: 1600px;
  }

  @media screen and(min-width: 4096px) {
    height: 1700px;
  }
}

.giftbuster {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  color: #ffffff;

  @media screen and(min-width: 1920px) {
    font-size: 36px;
    line-height: 40px;
  }

  @media screen and(min-width: 3200px) {
    font-size: 46px;
    line-height: 50px;
  }

  @media screen and(min-width: 3840px) {
    font-size: 56px;
    line-height: 60px;
  }
}

.heading {
  margin-top: 100px;
  font-family: PT serif;
  font-size: 128px;
  font-weight: 400;
  line-height: 120px;
  color: #ffffff;

  @media screen and(max-width: 1280px) {
    margin-top: 80px;
    font-size: 110px;
    line-height: 1;
  }

  @media screen and(max-width: 960px) {
    font-size: 80px;
  }

  @media screen and(max-width: 620px) {
    font-size: 60px;
    line-height: 72px;
  }

  @media screen and(max-width: 600px) {
    margin-top: 100px;
  }

  @media screen and(max-width: 360px) {
    margin-top: 50px;
    font-size: 50px;
    line-height: 62px;
  }

  @media screen and(max-width: 280px) {
    margin-top: 120px;
    font-size: 50px;
    line-height: 58px;
  }

  @media screen and(min-width: 1920px) {
    font-size: 148px;
    line-height: 150px;
  }

  @media screen and(min-width: 2560px) {
    font-size: 160px;
    line-height: 168px;
  }

  @media screen and(min-width: 3200px) {
    font-size: 180px;
    line-height: 188px;
  }

  @media screen and(min-width: 3840px) {
    font-size: 220px;
    line-height: 230px;
  }
}

.topContent {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 3;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 30px 140px 0 140px;

  @media screen and(max-width: 1152px) {
    padding: 30px 50px;
  }

  @media screen and(max-width: 960px) {
    padding: 30px 16px;
  }
}

.buttonContainer {
  display: flex;
  margin-top: 120px;

  @media screen and(max-width: 1280px) {
    margin-top: 100px;
  }

  @media screen and(max-width: 960px) {
    justify-content: center;
    margin-top: 60px;
  }

  @media screen and(max-width: 600px) {
    margin-top: 48px;
  }

  @media screen and(max-width: 280px) {
    flex-direction: column;
    align-items: center;
  }
}
