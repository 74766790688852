@mixin for-phone {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin for-tablet {
  @media (max-width: 800px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin for-small-phone {
  @media (max-width: 375px) {
    @content;
  }
}
