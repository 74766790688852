.block {
  background-color: #fff;
  box-shadow: 0px 16px 22px rgba(0, 0, 0, 0.1), 0px 34px 55px rgba(0, 0, 0, 0.05), 0px 13px 66px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  padding: 50px 44px;
  color: #000;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.4px;
  width: 430px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 25px;
}

.title {
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
}
